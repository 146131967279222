<template>
    <div>
      <h2>{{ $t('views.vouchers.discounts.add.title') }}</h2>
      <DiscountForm :discount="{}" @form:submit="submit" @form:cancel="navigate('Vouchers.Discounts')"></DiscountForm>
    </div>
  </template>

<script>
import MixinEntityBase from '@/components/mixins/EntityBase'
import DiscountForm from '../../../components/vouchers/DiscountForm.vue'

export default {
  name: 'Vouchers.Discounts.Add',
  components: { DiscountForm },
  mixins: [MixinEntityBase],
  props: {
    entity: {
      type: Object,
      required: true
    }
  },
  methods: {
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.entity.key}/updateEntity`, entity)
    },
    submit (newDiscount) {
      const entity = { ...this.entity }
      entity.value.discounts = entity.value.discounts || []
      entity.value.discounts.push(newDiscount)
      this.onSave(entity)
      this.navigate('Vouchers.Discounts')
    }
  }
}
</script>
